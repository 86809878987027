<script setup lang="ts">
import { getPosition } from '@core/utils/helpers'
import themeselectionQr from '@images/front-pages/backgrounds/mv.svg?url'

const userData = useCookie<any>('userData')

const areaProps = {
  multiple: true,
}

const searchQuery = ref('')

const menu = ref(false)
const facilityStore = useFacilityStore()

const filter = ref({
  search: '',
  name: '',
  facility_types: [],
  address: [],
  is_ng: false,
  is_ng_item: false,
  is_new: false,
  is_campaign: false,
  space_event: [],
  weekday_price: [0, 0],
  weekend_price: [0, 0],
  holiday_price: [0, 0],
  event_date: {
    start: '',
    end: '',
  },
  is_bookmark: false,
})

const typeStore = useTypeStore()
const { data: facilityTypes } = await typeStore.fetchBy({ category: 'facility' })

const navigateToPage = async (isMap: boolean) => {
  if (isMap) {
    if (!useCookie<any>('positionCenter').value)
      await getPosition()

    facilityStore.isMap = true
  }

  if (JSON.stringify(filter.value) === JSON.stringify(facilityStore.reset)) {
    facilityStore.isFilter = false
  }

  else {
    facilityStore.isFilter = true
    facilityStore.filter = filter
  }

  navigateTo({ name: 'search' })
}

const areaStore = useAreaStore()

const areas = ref()

const getAreas = async () => {
  areas.value = await areaStore.fetchArea()
}

const handleChange = () => {
  if (!areas.value)
    getAreas()
}
</script>

<template>
  <div class="landing-hero landing-hero-light-bg">
    <VContainer>
      <div
        class="d-flex flex-column gap-y-4 mx-auto"
        :class="$vuetify.display.mdAndUp ? 'w-75' : $vuetify.display.xs ? 'w-100' : 'w-75'"
      >
        <img :src="themeselectionQr">
      </div>
    </VContainer>
    <VContainer>
      <div
        class="d-flex flex-column gap-y-4 mx-auto w-75"
        :class="$vuetify.display.mdAndUp ? 'w-75' : $vuetify.display.xs ? 'w-100' : 'w-75'"
      >
        <VCard>
          <VToolbar color="primary">
            <div
              :class="$vuetify.display.mdAndUp ? 'text-h3' : 'text-h5'"
              class="text-center text-wrap mx-auto"
            >
              <span class="text-white font-weight-bold">＼今すぐ催事可能なスペースを探す／</span>
            </div>
          </VToolbar>
          <VCardText>
            <VTextField
              v-model="searchQuery"
              placeholder="施設名や住所をご入力ください"
              @change="filter.search = $event.target.value"
              @click:clear="filter.search = ''"
            />
          </VCardText>

          <VCardText>
            <VRow>
              <VCol
                cols="12"
                sm="4"
              >
                <VSelect
                  v-if="facilityTypes"
                  v-model="filter.facility_types"
                  placeholder="施設タイプ"
                  :items="facilityTypes"
                  multiple
                  item-title="name"
                  item-value="id"
                  clearable
                  clear-icon="tabler-x"
                />
              </VCol>

              <VCol
                cols="12"
                sm="4"
              >
                <ClientOnly>
                  <ElCascader
                    v-model="filter.address"
                    placeholder="エリア"
                    :props="areaProps"
                    :options="areas"
                    collapse-tags
                    collapse-tags-tooltip
                    value="value"
                    label="label"
                    filterable
                    clearable
                    @visible-change="handleChange"
                  />
                </ClientOnly>
              </VCol>

              <VCol
                cols="12"
                sm="4"
              >
                <VMenu
                  v-model="menu"
                  :close-on-content-click="false"
                >
                  <template #activator="{ props }">
                    <VBtn
                      block
                      variant="outlined"
                      v-bind="props"
                    >
                      価格
                    </VBtn>
                  </template>

                  <VCard>
                    <VCardText v-if="userData">
                      <div>
                        平日料金
                      </div>
                      <div class="d-flex justify-space-between align-center">
                        <VTextField
                          v-model="filter.weekday_price[0]"
                          type="number"
                          step="1000"
                          min="0"
                        />
                        <span> ~ </span>

                        <VTextField
                          v-model="filter.weekday_price[1]"
                          type="number"
                          step="1000"
                          min="0"
                          reverse
                        />
                      </div>
                      <VRangeSlider
                        v-model="filter.weekday_price"
                        min="0"
                        max="100000"
                        class="mt-2 mb-4"
                        step="1000"
                        @end="filter.weekday_price = $event"
                      />

                      <div>
                        土曜料金
                      </div>
                      <div class="d-flex justify-space-between align-center">
                        <VTextField
                          v-model="filter.weekend_price[0]"
                          type="number"
                          step="1000"
                          min="0"
                        />
                        <span> ~ </span>

                        <VTextField
                          v-model="filter.weekend_price[1]"
                          type="number"
                          step="1000"
                          min="0"
                          reverse
                        />
                      </div>
                      <VRangeSlider
                        v-model="filter.weekend_price"
                        min="0"
                        max="100000"
                        class="mt-2 mb-4"
                        step="1000"
                        @end="filter.weekend_price = $event"
                      />

                      <div>
                        日祝料金
                      </div>
                      <div class="d-flex justify-space-between align-center">
                        <VTextField
                          v-model="filter.holiday_price[0]"
                          type="number"
                          step="1000"
                          min="0"
                        />
                        <span> ~ </span>

                        <VTextField
                          v-model="filter.holiday_price[1]"
                          type="number"
                          step="1000"
                          min="0"
                          reverse
                        />
                      </div>
                      <VRangeSlider
                        v-model="filter.holiday_price"
                        min="0"
                        max="100000"
                        class="mt-2 mb-4"
                        step="1000"
                        @end="filter.holiday_price = $event"
                      />
                    </VCardText>
                    <VAlert
                      v-else
                      class="my-2 mx-2"
                      color="warning"
                    >
                      会員様のみ金額の検索が可能です。
                    </VAlert>
                  </VCard>
                </VMenu>
              </VCol>
            </VRow>
          </VCardText>

          <VCardText>
            <div class="d-sm-flex justify-center text-center">
              <VBtn
                color="primary"
                class="mr-2"
                @click="navigateToPage(true)"
              >
                <VIcon
                  class="d-sm-none"
                  icon="tabler-map-search"
                />
                <span class="d-none d-sm-block">マッピング検索</span>
              </VBtn>

              <VBtn
                color="primary"
                @click="navigateToPage(false)"
              >
                <VIcon
                  class="d-sm-none"
                  icon="tabler-search"
                />
                <span class="d-none d-sm-block">検索</span>
              </VBtn>
            </div>
          </VCardText>
        </VCard>
      </div>
    </VContainer>
  </div>
</template>

<style lang="scss" scoped>
.landing-hero {
  border-radius: 0 0 50px 50px;
  padding-block-end: 4rem;
  padding-block-start: 4rem;
}

.hero-animation-img{
  position: absolute;
  inline-size: 90%;
  inset-block-start: -25rem;
  inset-inline-start: 50%;
  margin-inline: auto;
  transform: translateX(-50%);
}

section {
  display: block;
}

.blank-section{
  background-color: rgba(var(--v-theme-surface));
  min-block-size: 25rem;
}

@media (min-width: 1280px) and (max-width: 1440px)
{
  .blank-section{
    min-block-size: 18rem;
  }

  .landing-hero{
    padding-block-end: 22rem;
  }

  .hero-animation-img{
    inset-block-start: -25rem;
  }
}

@media (min-width: 900px) and (max-width: 1279px)
{
  .blank-section{
    min-block-size: 13rem;
  }

  .landing-hero{
    padding-block-end: 14rem;
  }

  .hero-animation-img{
    inset-block-start: -17rem;
  }
}

@media (min-width: 768px) and (max-width: 899px)
{
  .blank-section{
    min-block-size: 12rem;
  }

  .landing-hero{
    padding-block-end: 12rem;
  }

  .hero-animation-img{
    inset-block-start: -15rem;
  }
}

@media (min-width: 600px) and (max-width: 767px)
{
  .blank-section{
    min-block-size: 12rem;
  }

  .landing-hero{
    padding-block-end: 8rem;
  }

  .hero-animation-img{
    inset-block-start: -11rem;
  }
}

@media (min-width: 425px) and (max-width: 600px)
{
  .blank-section{
    min-block-size: 8rem;
  }

  .landing-hero{
    padding-block-end: 8rem;
  }

  .hero-animation-img{
    inset-block-start: -9rem;
  }
}

@media (min-width: 300px) and (max-width: 424px)
{
  .blank-section{
    min-block-size: 4rem;
  }

  .landing-hero{
    padding-block-end: 6rem;
  }

  .hero-animation-img{
    inset-block-start: -7rem;
  }
}

.landing-hero::before{
  position: absolute;
  background-repeat: no-repeat;
  inset-block: 0;
  opacity: 0.5;
}

.landing-hero-light-bg{
  //background-image: linear-gradient(138.18deg, #eae8fd 0%, #fce5e6 94.44%);
  background-image: url("@images/front-pages/backgrounds/mv_bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (min-width: 600px)
{
  .hero-text-box {
    inline-size: 35rem;
    margin-block-end: 1rem;
    margin-inline: auto;
  }
}

.hero-title {
  animation: shine 2s ease-in-out infinite alternate;
  background: linear-gradient(to right, #28c76f 0%, #5a4aff 47.92%, #ff3739 100%);
  //  stylelint-disable-next-line property-no-vendor-prefix
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: rgba(0,0,0,0%);
}

@keyframes shine {
  0% {
    background-position: 0% 50%
  }

  80% {
    background-position: 50% 90%
  }

  100% {
    background-position: 91% 100%
  }
}

.hero-dashboard-img {
  margin-block: 0;
  margin-inline: auto;
  transform-style: preserve-3d;
  transition: all 0.35s;

  img{
    inline-size: 100%;
  }
}
</style>
